import * as React from "react"
import $ from 'jquery';
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import Seo from "@components/seo"
import "../../styles/index.scss"
import EditSettings from "../../components/settings/edit"

// Menu collape
const menuClick = (e) => {
  $("body").toggleClass("dashboard-menu-open");
}
// Menu collape

const AdditionalParams = {
  endPointName: "settings.create",
  upsert: true
}

const CurrencySettingsPage = (props) => (
  <>
    <Seo title="Resiland" />
    <div className="main-wrapper">
      <Sidebar menuActive="settings"
        action="Edit" />
      <header className="d-flex justify-content-between logout-wrapper">
        <a href="javascrip:void(0)" onClick={menuClick} className="d-lg-none d-md-inline-block">
          <i className="icon icon-dashboard-menu"></i>
        </a>
      </header>
      <EditSettings
        menu="Settings"
        parentPath="settings"
        pageType="Edit"
        groupMenu="settings"
        additionalParams={AdditionalParams} />
    </div>
  </>
)

export default CurrencySettingsPage
